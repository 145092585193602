import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="h-screen bg-primary/30 w-full flex flex-col items-center justify-center">
      <h1 className="-mt-16">404</h1>
    </div>
  </Layout>
)

export default NotFoundPage
